<template>
  <div>
    <div class="text-center">
      <h2 v-show="roadtrips.length > 0  && !isLoading" class="mb-3 text-center">Your Road Trips</h2>
      
      <ERTPLoadingSpinner v-model="isLoading">
        <ul class="list-group mb-3">
          <li class="list-group-item" v-for="roadtrip in roadtripsToDisplay">
            <div class="d-flex justify-content-between align-items-center">
              <span class="d-flex justify-content-start text-start">
                <a :href="roadtrip.link || '/'">
                  {{ roadtrip.title || "Your Epic Road Trip!" }}
                </a>
              </span>
              <span class="d-flex justify-content-end">
                <RoadtripEditButton :href="roadtrip.link || '#'"/>
                <RoadtripNavigateButton target="_blank" :href="roadtrip.navigationUrl || '#'"/>
                <EmailShareButton
                  @click.prevent="shareRoadtrip(roadtrip)"/>
                <RoadtripDeleteButton @click.prevent="onDeleteRoadtrip(roadtrip)"/>
              </span>                
            </div>
          </li>
        </ul>
        <a v-if="isMore" href="/roadtrips">
          More...      
        </a>
      </ERTPLoadingSpinner>
    </div>

    <ERTPDeleteRoadtripModal
      v-if="roadtripToDelete"
      :roadtrip="roadtripToDelete"
      @onSuccess="onSuccessDeleteRoadtrip"
      @onCancel="onCancelDeleteRoadtrip"
      @onError="onErrorDeleteRoadtrip"
    />

    <ERTPShareRoadtripModal
      :roadtrip="roadtripToShare"
      @onSuccess="onSuccessShareRoadtrip"
      @onCancel="onCancelShareRoadtrip"
      @onError="onErrorShareRoadtrip"
    />
                
  </div>

</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { clientOnly } from 'vike-vue/clientOnly'

import RoadtripEditButton from "./buttons/RoadtripEditButton.vue";
import RoadtripDeleteButton from "./buttons/RoadtripDeleteButton.vue";
import RoadtripNavigateButton from "./buttons/RoadtripNavigateButton.vue";
import EmailShareButton from "./buttons/EmailShareButton.vue";
import { Roadtrip } from "../models/roadtrip";
import ERTPLoadingSpinner from "./ERTPLoadingSpinner.vue";
import { useEmailSharing } from "../composables/emailSharing";
import { useResultMessage } from "../composables/resultMessage";
import { useServices } from "../composables/services";

const ERTPDeleteRoadtripModal = clientOnly(() => import('../components/modals/ERTPDeleteRoadtripModal.vue'));
const ERTPShareRoadtripModal = clientOnly(() => import('../components/modals/ERTPShareRoadtripModal.vue'));


const { roadtripsService } = useServices();

const isMore = computed(() => { 
  return (props.maxItems && roadtrips.value.length > props.maxItems) 
});

const { resultMessage } = useResultMessage();
const isLoading = computed(() => roadtripsService.isLoading.value);

const roadtrips = roadtripsService?.roadtrips;
const roadtripsToDisplay = computed(() => props.maxItems ? [...roadtrips.value].splice(0,  props.maxItems) : roadtrips.value);

const props = defineProps({
  maxItems: {
    type: Number
  }
});

const { 
  roadtripToShare,
  shareRoadtrip,
  onCancelShareRoadtrip,
  onSuccessShareRoadtrip,
  onErrorShareRoadtrip
} = useEmailSharing();

const roadtripToDelete = ref<Roadtrip>();

function onDeleteRoadtrip(roadtrip: Roadtrip) {
  resultMessage.value = undefined;
  roadtripToDelete.value = roadtrip;
};

function onCancelDeleteRoadtrip() {
  roadtripToDelete.value = undefined;
}

function onSuccessDeleteRoadtrip() {
  resultMessage.value = `Successfully deleted ${ roadtripToDelete.value?.title || "your Epic Road Trip." }`;
  roadtripToDelete.value = undefined;
}

function onErrorDeleteRoadtrip(message: string) {
  resultMessage.value = message || "Something went wrong. Please try again later.";
  roadtripToDelete.value = undefined;
}
</script>