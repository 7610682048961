<template>
  <EditButton 
    title="Edit road trip"
    :href="props.href"
    />
</template>

<script setup lang="ts">
import EditButton from './EditButton.vue';
const props = defineProps<{href: string}>();
</script>